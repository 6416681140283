import React, { useState } from "react";

const initialState = {
    email: "",
    password: "",
    wallet: null,
    profile: null,
    team: null,
    dob: null,
    loggedIn: false,
};
export default function AuthStore() {
    const [store, setAuth] = useState(initialState);

    const setEmail = (email) => setAuth((store) => ({ ...store, email }));
    const setWallet = (wallet) => setAuth((store) => ({ ...store, wallet }));
    const setName = (profile) => setAuth((store) => ({ ...store, profile }));
    const setTeam = (team) => setAuth((store) => ({ ...store, team }));
    const setDob = (dob) => setAuth((store) => ({ ...store, dob }));
    const setLoggedIn = (loggedIn) =>
        setAuth((store) => ({ ...store, loggedIn }));
    const setPassword = (password) =>
        setAuth((store) => ({ ...store, password }));
    const reset = () => setAuth(initialState);

    const actions = {
        setEmail,
        setWallet,
        setName,
        setTeam,
        setDob,
        setLoggedIn,
        setPassword,
        reset,
    };

    return {
        data: store,
        actions,
    };
}
