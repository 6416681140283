import { useState } from 'react';

const initialState = {
  isLoading: true,
};

const MintingState = () => {
  const [data, setData] = useState(initialState);
  const [contract, setContract] = useState(); // object is truthy

  const actions = {
    setMinting: (data) => setData(data),
    setMintingContract: (data) => setContract(data),
  };

  return {
    data,
    contract,
    actions,
  };
};

export default MintingState;
