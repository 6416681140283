import { useState } from "react";
import {
    ICollection,
    PriceRangeType,
    IMarketState,
    IPlayerMetadata,
    PlayerFiltersType,
    SortByType
} from "@/types";

const initialState = {
    trendingPlayers: null,
    topPerformers: null,
    marketdata: null,
    searchData: [],
    filters: {},
    sortBy: null,
    isLoading: true,
};

const MarketState = () => {
    const [data, setData] = useState<IMarketState>(initialState);

    const actions = {
        setTrendingPlayers: (trendingPlayers: ICollection[]) =>
            setData((data) => ({ ...data, trendingPlayers })),
        setTopPerformers: (topPerformers: ICollection[]) =>
            setData((data) => ({ ...data, topPerformers })),
        setMarketdata: (marketData: ICollection[]) =>
            setData((data) => ({ ...data, marketData })),
        setSearchData: (searchData: IPlayerMetadata[]) =>
            setData((data) => ({ ...data, searchData })),
        setWalletIsLoading: (isLoading: boolean) =>
            setData((data) => ({ ...data, isLoading })),
        setFiltersMarketplace: (filters: PlayerFiltersType) =>
            setData((data) => ({ ...data, filters })),
        setSortMarketplace: (sortBy: SortByType) =>
            setData((data) => ({ ...data, sortBy })),
        setPriceRange: (priceRange: PriceRangeType) =>
            setData((data) => ({ ...data, priceRange })),
    };

    return {
        data,
        actions,
    };
};

export default MarketState;
