import { useState } from "react";
import { ICollectionState, PlayerFiltersType, SortByType, PriceRangeType } from "@/types";

const initialState = {
    collection: [],
    isLoading: true,
    filters: {},
    sortBy: null,
};

const CollectionState = () => {
    const [data, setData] = useState<ICollectionState>(initialState);

    const actions = {
        setCollection: (data: ICollectionState) => setData(data),
        setIsLoading: (loading: boolean) =>
            setData((d) => ({ ...d, isLoading: loading })),
        setFiltersCollection: (filters: PlayerFiltersType) =>
            setData((d) => ({ ...d, filters })),
        setSortCollection: (sortBy: SortByType) =>
            setData((d) => ({ ...d, sortBy })),
        setPriceRangeCollection: (priceRange: PriceRangeType) =>
            setData((data) => ({ ...data, priceRange })),
    };

    return {
        data,
        actions,
    };
};

export default CollectionState;
