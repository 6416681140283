import { useState } from "react";
import { IContestState } from "@/types";

const initialState = {
  allContests: [],
  contests: [],
  history: [],
  isLoading: true,
};

const ContestState = () => {
  const [data, setData] = useState<IContestState>(initialState);

  const actions = {
    setContests: (data: IContestState) => setData(data),
  };

  return {
    data,
    actions,
  };
};

export default ContestState;
