import React, { createContext } from "react";
import AuthStore from "./auth";
import CollectionState from "./CollectionState";
import ContestState from "./ContestState";
import MarketState from "./MarketState";
import MintingState from "./MintingState";
import PageState from "./PageState";
import WalletState from "./WalletState";

export const StoreContext = createContext<any>(null);

export function ContextWrapper({ children }) {
  const Auth = AuthStore();
  const Collection = CollectionState();
  const Contest = ContestState();
  const Minting = MintingState();
  const Wallet = WalletState();
  const Page = PageState();
  const Market = MarketState();

  const data = {
    auth: Auth.data,
    collection: Collection.data || {},
    contest: Contest.data || {},
    minting: Minting.data || {},
    wallet: Wallet.data || {},
    page: Page.data || "",
    market: Market.data || {},
  };

  const contracts = {
    minting: Minting.contract || {},
  };

  const actions = {
    auth: Auth.actions,
    ...Collection.actions,
    ...Contest.actions,
    ...Minting.actions,
    ...Wallet.actions,
    ...Page.actions,
    ...Market.actions,
  };

  return (
    <StoreContext.Provider value={{ data, actions, contracts }}>
      {children}
    </StoreContext.Provider>
  );
}
